<template>
  <div class="user">
    <div class="formWrap">
      <span class="formName">负责人</span>
      <el-input
        class="formBody"
        v-model="name"
        clearable
        placeholder="请输入内容"
      ></el-input>
      <span class="formName">手机号码</span>
      <el-input
        class="formBody"
        v-model="account"
        clearable
        placeholder="请输入内容"
      ></el-input>
      <el-button class="mr10" type="primary" @click="onList()">查询 </el-button>
      <el-button @click="onReList()">重置</el-button>
    </div>

    <el-button type="primary" icon="el-icon-plus" @click="onAdd()">添加</el-button>
    <el-table :data="listData" class="list">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column
        label="企业名称"
        prop="sysEnterprise.name"
      ></el-table-column>
      <el-table-column prop="sysEnterprise.socialCreditCode" label="统一社会信用代码"></el-table-column>
      <el-table-column prop="sysEnterprise.address" label="企业注册地址"></el-table-column>
      <el-table-column prop="realName" label="负责人" width="100"></el-table-column>
      <el-table-column prop="phone" label="手机号码" width="120"></el-table-column>
      <el-table-column
      prop="createTime"
      label="注册时间"
      width="180"
      ></el-table-column>
      <el-table-column label="对接权限" width="250">
        <template slot-scope="scope">
          <span :class="scope.row.devicePermission.dust == 0 ? 'red' : 'green'" @click="handledevicePermission(0, scope.row)">扬尘噪音</span>
          <span :class="scope.row.devicePermission.tower == 0 ? 'red' : 'green'" @click="handledevicePermission(1, scope.row)">塔式起重机</span>
          <span :class="scope.row.devicePermission.elevator == 0 ? 'red' : 'green'" @click="handledevicePermission(2, scope.row)">施工升降机</span>
        </template>
      </el-table-column>
      <el-table-column label="用户状态" width="120">
        <template slot-scope="scope">
          <el-button type="success" size="mini" icon="el-icon-user" plain v-if="scope.row.status == 0"  @click="handleStatus(scope.row)">正常</el-button>
          <el-button type="danger" size="mini" icon="el-icon-lock" plain v-if="scope.row.status == 1" @click="handleStatus(scope.row)">锁定</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @current-change="onList"
      :current-page.sync="pageNum"
      :page-size="10"
      layout="total, prev, pager, next"
      :total="totals"
    >
    </el-pagination>

    <el-dialog title="用户信息" :visible.sync="showAddUser">
      <el-form>
        <el-form-item label="企业名称" label-width="140px">
          <el-input
            class="formBodyPlus"
            v-model="addUserData.sysEnterprise.name"
            autocomplete="off"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" label-width="140px">
          <el-input
            class="formBodyPlus"
            v-model="addUserData.sysEnterprise.socialCreditCode"
            autocomplete="off"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="企业注册地址" label-width="140px">
          <el-cascader
            ref="cascader"
            class="formBodyPlus"
            :options="options"
            v-model="cascader"
            @change="handleChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="负责人" label-width="140px">
          <el-input
            class="formBodyPlus"
            v-model="addUserData.realName"
            autocomplete="off"
            @change="handleChangeName"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="性别" label-width="140px">
          <el-select
            class="formBodyPlus"
            v-model="addUserData.sex"
            placeholder="请选择"
            clearable
          >
            <el-option label="男" :value="0" />
            <el-option label="女" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码" label-width="140px">
          <el-input
            class="formBodyPlus"
            v-model="addUserData.phone"
            autocomplete="off"
            clearable
            @change="handleChangeAccount"
          ></el-input>
        </el-form-item>
        <el-form-item label="登录密码" label-width="140px">
          <el-input
            class="formBodyPlus"
            v-model="addUserData.password"
            autocomplete="off"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="onClose()">取消</el-button>
        <el-button type="primary" @click="handleConfirm()">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="less" scoped>
.user {
  padding: 20px;
}

.green {
  color: green;
  margin-right: 10px;
}
.red {
  color: red;
  margin-right: 10px;
}
</style>

<script>
import { regionData } from 'element-china-area-data'

export default {
  name: "User",
  components: {},
  data() {
    return {
      options: regionData,
      cascader: null,

      name: null,
      account: null,

      listData: [],
      pageNum: 1,
      pageSize: 10,
      totals: 0,

      showAddUser: false,

      addUserData: {
        account: null,
        password: null,
        name: null,
        realName: null,
        phone: null,
        sex: null,
        sysEnterprise: {
          name: null,
          socialCreditCode: null,
          address: null,
        },
      },
    };
  },
  computed: {},
  mounted() {
    this.onList()
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取列表数据
    onList: function () {
      var that = this;
      var data = {
        name: this.name,
        account: this.account,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/sysUser/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.listData = res.data.list;
          that.totals = res.data.totals;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onReList: function () {
      this.name = null
      this.account = null
      this.onList()
    },
    onAdd: function () {
      this.addUserData = {
        account: null,
        password: null,
        name: null,
        realName: null,
        phone: null,
        sex: null,
        sysEnterprise: {
          name: null,
          socialCreditCode: null,
          address: null,
        },
      }
      this.cascader = null
      this.showAddUser = true
    },
    onClose: function () {
      this.addUserData = {
        account: null,
        password: null,
        name: null,
        realName: null,
        phone: null,
        sex: null,
        sysEnterprise: {
          name: null,
          socialCreditCode: null,
          address: null,
        },
      }
      this.cascader = null
      this.showAddUser = false
    },
    // 确定
    handleConfirm: function () {
      this.$confirm("此操作将保存数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onConfirm(this.addUserData);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 修改用户状态
    handleStatus(row) {
      console.log('handleStatus', row);
      var msg
      var status
      if (row.status == 0) {
        msg = "锁定"
        status = 1
      } else {
        msg = "允许"
        status = 0
      }
      this.$confirm(`此操作将${msg}用户登录, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onConfirm({
            id: row.id,
            status: status,
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 修改对接权限
    handledevicePermission: function (device, data) {
      var devicePermission = JSON.parse(JSON.stringify(data.devicePermission))
      var id = data.id
      if (device == 0) {
        if (devicePermission.dust == '0') {
          devicePermission.dust = '1'
        }
        else if (devicePermission.dust == '1') {
          devicePermission.dust = '0'
        }
      }
      if (device == 1) {
        if (devicePermission.tower == '0') {
          devicePermission.tower = '1'
        }
        else if (devicePermission.tower == '1') {
          devicePermission.tower = '0'
        }
      }
      if (device == 2) {
        if (devicePermission.elevator == '0') {
          devicePermission.elevator = 1
        }
        else if (devicePermission.elevator == '1') {
          devicePermission.elevator = '0'
        }
      }
      console.log('devicePermission =>', devicePermission);
      this.$confirm(`此操作将操作用户接入权限, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onSaveDevicePermission({
            userId: id,
            permission: JSON.stringify(devicePermission)
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 提交数据
    onConfirm: function (data) {
      var that = this;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/sysUser/submit",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.$message({
              type: "success",
              message: "操作成功",
            });
          } else {
            that.$message({
              type: "info",
              message: res.msg,
            });
          }
          that.onClose()
          that.onList()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 提交用户对接权限
    onSaveDevicePermission: function (data) {
      var that = this;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/sysUser/devicePermission",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            that.$message({
              type: "success",
              message: "操作成功",
            });
          } else {
            that.$message({
              type: "info",
              message: res.msg,
            });
          }
          that.onClose()
          that.onList()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleChange(value) {
      let nodeInfo = this.$refs['cascader'].getCheckedNodes()
      var arr = []
      arr.unshift(nodeInfo[0].label)
      arr.unshift(nodeInfo[0].parent.label)
      arr.unshift(nodeInfo[0].parent.parent.label)
      this.addUserData.sysEnterprise.address = arr.join('')
    },
    handleChangeName: function (value) {
      this.addUserData.name = value
    },
    handleChangeAccount: function (value) {
      this.addUserData.account = value
    },
  },
};
</script>
